<template>
  <div>
    <div class="cronTimesWrapper">
      <div class="cronselectoLine">
        <v-row
          no-gutters
          v-for="(cronline, increment) in cronArray"
          :key="increment"
        >
          <div class="mr-2">
            <associatedOption
              type="select"
              :fieldAttrInput="{ style: 'max-width:100px',id:'schedule_every_'+increment }"
              :fieldAttributes="{ options: every, name: 'Every' ,sort:false}"
              :value="cronline.everyValue"
              @input="updateline(increment, 'everyValue', ...arguments)"
              :templateContent="result"
            ></associatedOption>
          </div>
          <div class="mr-2" v-if="cronline.day">
            <associatedOption
              type="select"
              :fieldAttrInput="{ style: 'max-width:200px' }"
              :fieldAttributes="{ options: days, name: 'on' }"
              v-model="cronline.day"
              @input="updateline(increment, 'day', ...arguments)"
              :templateContent="result"
            ></associatedOption>
          </div>
          <div class="mr-2" v-if="cronline.hours">
            <associatedOption
              type="select"
              :fieldAttrInput="{ style: 'max-width:100px' }"
              :fieldAttributes="{ options: hours, name: 'hour' }"
              v-model="cronline.hours"
              @input="updateline(increment, 'hours', ...arguments)"
              :templateContent="result"
            ></associatedOption>
          </div>
          <div class="mr-2" v-if="cronline.minute">
            <associatedOption
              type="select"
              :fieldAttrInput="{ style: 'max-width:100px' }"
              :fieldAttributes="{ options: mins, name: 'minute' }"
              v-model="cronline.minute"
              @input="updateline(increment, 'minute', ...arguments)"
              :templateContent="result"
            ></associatedOption>
          </div>
          <div class="mt-2">
            <trash @click="removecronLine(increment)" :result="result"></trash>
          </div>
        </v-row>
      </div>
    </div>

    <primaryButton
      type="button"
      @click="addCronTime()"
      label="Add Time"
      icon="mdi-plus"
      color="primary"
      :disabled="disabled"
    ></primaryButton>

    <input type="hidden" :value="JSON.stringify(cronObject)" name="c_obj" />
  </div>
</template>

<script>
import trash from "@/commonComponents/dynamic/trash.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  props: ["fieldAttributes", "field", "result","value"],
  components: {
    associatedOption,
    primaryButton,
    trash,
  },
  data() {
    return {
      every: {
        "*:*/1:00": "1 Min",
        "*:*/3:00": "3 Min",
        "*:*/5:00": "5 Min",
        "*/1:0:00": "1 Hrs",
        "*/2:0:00": "2 Hrs",
        "*/3:0:00": "3 Hrs",
        hour: "hour",
        day: "day",
        week: "week",
      },
      mins: this.getmins(),
      hours: this.getHours(),
      days: {
        su: "Sunday",
        mo: "Monday",
        tu: "Tuesday",
        we: "Wednesday",
        th: "Thursday",
        fr: "Friday",
        sa: "Saturday",
      },
    };
  },
  methods: {
    removecronLine(index) {
      this.cronArray.splice(index, 1);
      this.updateValue(this.cronArray);
    },
    getHours() {
      let hours = [];

      for (var i = 0; i < 24; i++) {
        var j = i < 10 ? "0" : "";

        hours.push(j + i);
      }
      return hours;
    },
    getmins() {
      let mins = [];

      for (var i = 0; i < 60; i++) {
        var j = i < 10 ? "0" : "";

        mins.push(j + i);
      }
      return mins;
    },

    addCronTime() {
      this.cronArray.push({ everyValue: "*:*/1:00" });
      this.updateValue(this.cronArray);
    },
    updateline(index, field, value) {
      this.$set(this.cronArray[index], field, value);
      this.updateValue(this.cronArray);
    },
    addCronLines(inputArray, periods, day) {
      periods.forEach(function (value) {
        let returnedObject = {};
        if (value.indexOf("/") > -1) {
          returnedObject.everyValue = value;
        } else {
          var valueArray = value.split(":");
          //console.log(valueArray);
          if (day != "") {
            returnedObject.day = day;
            returnedObject.everyValue = "week";
          } else {
            if (valueArray[0] == "*") {
              returnedObject.everyValue = "hour";
            } else {
              returnedObject.everyValue = "day";
            }
          }
          if (returnedObject.everyValue != "hour") {
            let hours = valueArray[0];
            var j = hours < 10 ? "0" : "";
            returnedObject.hours = j + hours;
          }
          let mins = valueArray[1];
          var m = mins < 10 ? "0" : "";
          returnedObject.minute = m + mins;
        }
        inputArray.push(returnedObject);
      });
    },

    updateValue(cronArray) {
      let weekly = {};
      let daily = [];
      cronArray.forEach(function (line) {
        if (line.everyValue.indexOf("/") > -1) {
          daily.push(line.everyValue);
        } else {
          if (line.everyValue == "hour") {
            daily.push("*:" + parseIntgetZerro(line.minute) + ":00");
          } else if (line.everyValue == "day") {
            daily.push(
              parseIntgetZerro(line.hours) +
                ":" +
                parseIntgetZerro(line.minute) +
                ":00"
            );
          } else if (line.everyValue == "week") {
            if (typeof weekly[line.day] == "undefined") {
              weekly[line.day] = [];
            }
            weekly[line.day].push(
              parseIntgetZerro(line.hours) +
                ":" +
                parseIntgetZerro(line.minute) +
                ":00"
            );
          }
        }
      });

      this.cronObject = { daily: daily, weekly: weekly };
    },
  },

  computed: {
    disabled() {
      let disabled = false;
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
    cronObject: {
      get() {
        return JSON.parse(this.value);
      },
      set(newVal) {
        this.$emit("input", JSON.stringify(newVal));
      },
    },
    cronArray() {
      let cronArray = [];
      var storedCronTimes = this.cronObject;

      var daily = storedCronTimes["daily"];
      var weekly = storedCronTimes["weekly"];
      if (typeof daily != "undefined") {
        this.addCronLines(cronArray, daily, "");
      }
      if (typeof weekly != "undefined") {
        for (let [index, value] of Object.entries(weekly)) {
          this.addCronLines(cronArray, value, index);
        }
      }
      return cronArray;
    },
  },
};

function parseIntgetZerro(value) {
  let val = 0;
  if (!Number.isNaN(value) && value != "NaN" && typeof value != "undefined") {
    val = parseInt(value);
  }
  return val;
}
</script>